<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                  {{ $t('fertilizerSales.ministryFertilizerAllocation') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple bordered style="font-size:12px;color:#555555;line-height:2px">
                  <tbody>
                    <tr>
                      <th>{{ $t('globalTrans.fiscalYear') }}</th>
                      <td>{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                      <th>{{ $t('fertilizerSales.month') }}</th>
                      <td>{{ $i18n.locale === 'bn' ? formData.month_bn : formData.month_en }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.allocationDate') }}</th>
                      <td>{{ formData.allocalion_date | dateFormat }}</td>
                      <th>{{ $t('fertilizerSales.allocationType') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.allocation : formData.allocation_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('fertilizerSales.comment') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.comments : formData.comments_bn }}</td>
                      <th></th>
                      <td></td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-row>
             <b-col lg="12" sm="12">
            <b-table-simple bordered style="font-size:12px;color:#555555;line-height:2px">
                  <b-thead head-variant="secondary">
                      <br>
                      <b-tr>
                          <b-th rowspan="2" class="text-center" width="12%">{{ $t('globalTrans.sl_no') }}</b-th>
                          <b-th colspan="2" rowspan="2" class="text-center" width="25%">{{ $t('movement.godownNameDistrict') }}</b-th>
                          <b-th :colspan="fertilizerNameList.length" class="text-center">{{ $t('movement.fertilizerName') }}{{ ' [' + $t('fertilizerSales.m_ton') + ']'}}</b-th>
                      </b-tr>
                      <b-tr>
                          <b-th v-for="(fertilizer, index) in fertilizerNameList" :key="index" class="text-center">{{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }}</b-th>
                      </b-tr>
                    <b-tr v-for="(district, index) in districtList" :key="index">
                        <b-td class="text-center" width="12%">{{ $n(index + 1) }}</b-td>
                            <b-td colspan="2" class="text-center" width="25%">{{ currentLocale === 'en' ? district.text_en : district.text_bn }}</b-td>
                            <b-td v-for="(fertilizer, index1) in fertilizerNameList" :key="index1" class="text-center">{{ $n(formData.allocation_details[index][index1].amount) }}</b-td>
                    </b-tr>
                    <b-tr>
                          <b-th colspan="3" class="text-right">{{ $t('movement.total') }}</b-th>
                          <b-th v-for="(fertilizer, index) in fertilizerNameList" :key="index" class="text-center">{{ $n(formData.total[index]) }}</b-th>
                      </b-tr>
                      <b-tr>
                          <b-th colspan="3"></b-th>
                          <b-th v-for="(fertilizer, index) in fertilizerNameList" :key="index" class="text-center">{{ $t('movement.mTon') }}</b-th>
                      </b-tr>
                  </b-thead>
                </b-table-simple>
             </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { seedFertilizerServiceBaseUrl } from '@/config/api_config'
// import { lcList, lcInfo, programManagementShow } from '@/modules/seeds-fertilizer-service/fertilizer/movement/api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.tempData = tmp
      if (tmp) {
        const tmp = this.getFormData()
        this.formData = tmp
        this.tempData = tmp
        if (tmp) {
          var temp = []
          this.districtList.forEach((district, districtIndex) => {
              var fertilizerAgent = []
              this.fertilizerNameList.forEach((fertilizer, fertilizerIndex) => {
                  const allocationDetail = this.getAllocationDetails(fertilizer.value, district.value)
                  const item = {
                      fertilizer_id: fertilizer.value,
                      district_id: district.value,
                      amount: allocationDetail ? allocationDetail.quantity : null,
                      ministry_allo_details_id: allocationDetail ? allocationDetail.id : ''
                  }
                  fertilizerAgent.push(item)
              })
              temp.push(fertilizerAgent)
          })
          this.formData.allocation_details = temp
          this.formData.total = []
          this.getTotalAmounts(this.formData.allocation_details)
        }
      }
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      formData: {
        id: '',
        allocation_date: '',
        memo_no: '',
        lot_no: '',
        lc_no: '',
        ship_name: '',
        ship_name_bn: '',
        country_name: '',
        country_name_bn: '',
        fertilizer_id: '',
        fertilizer_name: '',
        bill_amount: '',
        total_allocated_amount: '',
        port_info: []
      },
      totalAvailableAmount: 0,
      currentAvailableAmount: 0,
      lcList: [],
      lcInfo: {},
      portDetailsInfo: []
    }
  },
  computed: {
    districtList: function () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    allocationTypeList: function () {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
      },
    monthList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getFormattedDate (date) {
      const dateArr = date.split('-')
      return this.$n(parseInt(dateArr[0]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[1]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[2]), { useGrouping: false })
    },
    getAllocationDetails (fertilizerId, districtId) {
        const data = this.tempData.ministry_allocation_details.find(doc => (doc.fertilizer_id === fertilizerId) && (doc.district_id === districtId))
        if (typeof data !== 'undefined') {
            return data
        } else {
            return null
        }
    },
    getTotalAmounts (data) {
        const n = this.fertilizerNameList.length
        const a = new Array(n)
         for (let i = 0; i < n; ++i) {
            a[i] = 0
         }
         const sum1 = a
        data.forEach((district, districtIndex) => {
            district.forEach((item, fertilizerIndex) => {
                if (item.amount) {
                    sum1[fertilizerIndex] += parseInt(item.amount)
                }
                this.formData.total[fertilizerIndex] = sum1[fertilizerIndex]
            })
        })
    },
    pdfExport () {
        const reportTitle = this.$t('fertilizerSales.ministryFertilizerAllocation')
        ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this, this.fertilizerNameList, this.districtList)
    }
  }
}
</script>
