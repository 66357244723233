<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12" class="form-for-textarea">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                      <b-row>
                      <b-col lg="6" sm="12">
                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.fiscalYear')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Month"  vid="month_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="month_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('fertilizerSales.month')}}  <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.month"
                                  :options="monthList"
                                  id="month_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Allocation Date" vid="allocation_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="allocation_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.allocationDate')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  class="fromDate"
                                  v-model="formData.allocation_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Allocation Type"  vid="allocation_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="allocation_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('fertilizerSales.allocationType')}}  <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.allocation_type_id"
                                  :options="allocationTypeList"
                                  id="allocation_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Comment"  vid="comment" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="comment"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('fertilizerSales.comment')}} <span class="text-danger"></span>
                                </template>
                                <b-form-input
                                    id="comment"
                                    v-model="formData.comments"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Comment (Bn)"  vid="comment_bn" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="comment"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.commentsBn')}} <span class="text-danger"></span>
                                </template>
                                <b-form-input
                                    id="comment"
                                    v-model="formData.comments_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                      <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                        <b-thead head-variant="secondary">
                            <b-tr>
                                <b-th rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center" rowspan="2">{{ $t('fertilizerSales.nameOfDistrict') }}</b-th>
                                <b-th class="text-center" :colspan="fertilizerNameList.length">{{ $t('movement.fertilizerName') }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th v-for="(fertilizer, index) in fertilizerNameList" :key="index" class="text-center">{{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }} <br> {{ '(' + $t('movement.mTon') + ')' }} </b-th>
                            </b-tr>
                          <b-tr v-for="(district, index) in districtList" :key="index">
                              <b-th class="text-center">{{ $n(index + 1) }}</b-th>
                                  <b-th class="text-center">{{ currentLocale === 'en' ? district.text_en : district.text_bn }}</b-th>
                                  <b-th v-for="(fertilizer, index1) in fertilizerNameList" :key="index1"><b-form-input
                                    :id="'allocated_amount_godown_'+index+'_agent_'+index1"
                                    :ref="'allocated_amount_godown_'+index+'_agent_'+index1"
                                    :placeholder="currentLocale === 'en' ?  fertilizer.text_en : fertilizer.text_bn"
                                    v-model="formData.allocation_details[index][index1].amount"
                                    @input="getTotalAmount"
                                ></b-form-input></b-th>
                          </b-tr>
                          <b-tr>
                                <b-th colspan="2" class="text-center">{{ $t('movement.total') }}</b-th>
                                <b-th v-for="(fertilizer, index) in fertilizerNameList" :key="index"><b-form-input
                                    :id="'allocated_'+(index+1)"
                                    :ref="'allocated_'+(index+1)"
                                    readonly
                                    v-model="formData.total[index]"
                                ></b-form-input></b-th>
                            </b-tr>
                            <b-tr>
                                <b-th colspan="2"></b-th>
                                <b-th v-for="(fertilizer, index) in fertilizerNameList" :key="index" class="text-center">{{ $t('movement.mTon') }}</b-th>
                            </b-tr>
                        </b-thead>
                      </b-table-simple>
                      <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
      <!-- <pre>{{$store.state.SeedsFertilizer.commonObj.monthListfiscalYear}}</pre> -->
      <!-- <pre>{{formData}}</pre> -->
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { ministryAllocationStore, ministryAllocationUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.tempData = tmp
      if (tmp) {
         var temp = []
         this.districtList.forEach((district, districtIndex) => {
            var fertilizerAgent = []
            this.fertilizerNameList.forEach((fertilizer, fertilizerIndex) => {
                const allocationDetail = this.getAllocationDetails(fertilizer.value, district.value)
                const item = {
                    fertilizer_id: fertilizer.value,
                    district_id: district.value,
                    amount: allocationDetail ? allocationDetail.quantity : null,
                    ministry_allo_details_id: allocationDetail ? allocationDetail.id : ''
                }
                fertilizerAgent.push(item)
            })
            temp.push(fertilizerAgent)
        })
        this.formData.allocation_details = temp
        this.formData.total = []
        this.getTotalAmounts(this.formData.allocation_details)
      }
    } else {
     temp = []
    this.districtList.forEach((district, districtIndex) => {
        var fertilizerData = []
        this.fertilizerNameList.forEach((fertilizer, fertilizerIndex) => {
            const item = {
                district_id: district.value,
                fertilizer_id: fertilizer.value,
                amount: ''
            }
            fertilizerData.push(item)
        })
        temp.push(fertilizerData)
    })
    this.formData.allocation_details = temp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        allocation_date: '',
        allocation_details: [],
        fiscal_year_id: 0,
        allocation_type_id: 0,
        month: 0,
        godown: [],
        total: [],
        comments: '',
        comments_bn: ''
      },
      index: ''
    }
  },
  computed: {
    districtList: function () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    allocationTypeList: function () {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
      },
    monthList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${ministryAllocationUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, ministryAllocationStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
        this.loading = false
      } else {
        this.$refs.form.setErrors(result.errors)
        this.loading = false
      }
    },
    getAllocationDetails (fertilizerId, districtId) {
        const data = this.tempData.ministry_allocation_details.find(doc => (doc.fertilizer_id === fertilizerId) && (doc.district_id === districtId))
        if (typeof data !== 'undefined') {
            return data
        } else {
            return null
        }
    },
    getTotalAmounts (data) {
        const n = this.fertilizerNameList.length
        const a = new Array(n)
         for (let i = 0; i < n; ++i) {
            a[i] = 0
         }
         const sum1 = a
        data.forEach((district, districtIndex) => {
            district.forEach((item, fertilizerIndex) => {
                if (item.amount) {
                    sum1[fertilizerIndex] += parseFloat(item.amount)
                }
                this.formData.total[fertilizerIndex] = sum1[fertilizerIndex]
            })
        })
    },
    getTotalAmount (event) {
        const n = this.fertilizerNameList.length
        const a = new Array(n)
         for (let i = 0; i < n; ++i) {
            a[i] = 0
         }
         const sum1 = a
        this.formData.allocation_details.forEach((district, districtIndex) => {
            district.forEach((item, fertilizerIndex) => {
                if (item.amount) {
                    sum1[fertilizerIndex] += parseFloat(item.amount)
                }
                this.formData.total[fertilizerIndex] = sum1[fertilizerIndex]
            })
        })
    }
  }
}
</script>
